@import "../../global";

.addButton {

  &.square {
    @include flex-centered;
    flex-direction: column;
    background: none;
    cursor: pointer;
    border: 1px dashed $text-secondary;
    box-sizing: border-box;
    padding: 12px;

    &:focus {
      outline: none;
    }

    &:hover {
      border: 1px solid $text-secondary;
      background-color: $bg-secondary;
    }

    .icon {
      height: 36px;
      width: 36px;
      margin-bottom: 12px;
    }

    .text {
      @include eyebrow;
    }
  }

  &.rectangle {
    background: #292D31;
    color: #fff;
    border-radius: 4px;

    .plus {
      position: relative;
      margin-right: 4px;
      top: 2px;
    }
  }
}