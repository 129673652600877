@import "../../global";

.sortableList {
  > * {
    position: relative;
    padding-left: 20px;
    transform: translate3d(0px, 0px, 0px);
    top: 0;
    left: 0;

    &::before {
      display: block;
      content: url('../../assets/svg/grabDots.svg');
      width: 8px;
      height: 15px;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.sortableGhost {
      opacity: 0.3;
    }
  }
}
