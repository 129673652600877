@import "../../global";


.autocomplete {
  &.noIcons {
    .dropdown {
      .search {
        grid-template-columns: 1fr 24px;

        .icon {
          display: none;
        }
      }

      .menu {
        .item {
          grid-template-columns: 1fr 24px;
        }

        .icon {
          display: none;
        }
      }
    }
  }

  .background {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0);
    z-index: 5;
  }

  .dropdown {
    position: relative;

    .title {
      @include eyebrow;
      margin-bottom: 12px;
    }

    &.open {
      z-index: 6;
      .search {
        border: 1px solid $accent-primary;

        &:hover {
          border: 1px solid $accent-primary;
        }
      }
    }

    .search {
      position: relative;
      min-width: 200px;
      width: 100%;
      border: 1px solid $rule;
      background: $bg-secondary;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 7px 14px;
      display: inline-grid;
      grid-template-columns: 24px 1fr 24px;
      grid-column-gap: 10px;

      &:hover {
        border: 1px solid $text-secondary;
      }

      .icon {
        position: relative;
        height: 24px;
        width: 24px;

        .image {
          height: 100%;
          width: 100%;
        }
      }

      .input {
        background: none;
        border: none;
        color: $text-primary;
        font-size: 1.6rem;

        &:focus {
          outline: none;
        }
      }

      .close {
        position: relative;
        cursor: pointer;

        .svgIcon {
          position: absolute;
          top: 7px;
          left: 7px;
          width: 10px;
          height: 10px;
          background-color: $text-secondary;
          -webkit-mask: url('../../assets/svg/x.svg') no-repeat center;
        }
      }
    }

    .menu {
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
      display: flex;
      max-height: 300px;
      min-width: 200px;
      width: 100%;
      background: $bg-secondary;
      border: 1px solid $rule;
      box-sizing: border-box;
      border-radius: 6px;
      flex-direction: column;
      overflow-y: auto;
      z-index: 10;

      .item {
        display: inline-grid;
        grid-template-columns: 24px 1fr 24px;
        grid-column-gap: 10px;
        padding: 10px 14px;
        font-size: 1.6rem;
        box-sizing: border-box;
        border-top: 1px solid $rule;
        cursor: pointer;
        user-select: none;

        &:first-of-type {
          border: none;
        }

        &:hover {
          background-color: $bg-primary;

          .label {
            color: $accent-primary;
          }
        }

        .icon {
          position: relative;
          height: 24px;
          width: 24px;

          .image {
            height: 100%;
            width: 100%;
          }
        }

        .label {
          display: flex;
          align-items: center;
          height: 24px;
        }

        .chevronRight {
          @include chevron;
          position: static;
          width: 100%;
          height: 100%;
        }

        .chevronLeft {
          @include chevron;
          position: static;
          width: 100%;
          height: 100%;
          transform: rotateY(180deg);
        }
      }
    }
  }
}
