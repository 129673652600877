@import '../../global';

.searchFilter {
  @include grid;
  margin: $gut-width 0;

  .input {
    grid-column: col-start 1 / col-end 7;

    &:hover {
      border: 1px solid $text-secondary;
    }
  
    &:focus {
      outline: none;
      border: 1px solid $accent-primary;
    }
  }

  .action {
    display: flex;
    margin-left: 20px !important;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);

    .menuFloat {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      border-radius: 8px;
      border: 1px solid $text-disabled;

      .menuHeader {
        align-items: center;
        color: $text-primary;
        display: flex;
        flex: 1 0 0;
        font-family: Colfax;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 12px 16px;
      }

      .groupFilter {
        background-color: $bg-secondary;
        width: 100%;

        .groupFilterLabel {
          color: $text-secondary;
          font-family: Colfax;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 1px;
          line-height: 18px;
          padding: 12px 8px;
          text-transform: uppercase;
        }
        .groupFilterInput {
          padding: 0px 8px 12px 8px;
        }
        .groupFilterItems {
          padding: 16px 14px;
        }
      }

      .menuFooter {
        align-items: center;
        color: $text-primary;
        display: flex;
        flex: 1 0 0;
        font-family: Colfax;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 8px;

        .btnClear {
          align-items: center;
          background-color: $bg-primary;
          border-radius: 100px;
          color: $chaos-primary;
          cursor: pointer;
          font-weight: 700;
          gap: 10px;
          height: 38px;
          justify-content: center;
          padding: 10px 32px;
          text-transform: uppercase;
          transition: all 0.1s ease-in-out;
          user-select: none;
          width: 120px;

          &:focus {
            outline: none;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        .btnSave {
          align-items: center;
          background-color: $accent-primary;
          border-radius: 100px;
          color: $bg-tertiary;
          font-family: 'Colfax';
          font-size: 16px;
          font-weight: 700;
          cursor: pointer;
          gap: 8px;
          height: 38px;
          justify-content: center;
          padding: 11px 24px;
          text-transform: uppercase;
          transition: all 0.1s ease-in-out;
          user-select: none;
          width: 120px;

          &:focus {
            outline: none;
          }

          &:hover {
            background-color: $accent-hover;
            color: $bg-tertiary;
          }

          &:disabled {
            pointer-events: none;
            background-color: $bg-disabled;
            color: $text-disabled;
          }

          &.disabled {
            pointer-events: none;
            background-color: $text-secondary;
            color: $text-disabled;
          }
        }
      }
    }
  }
}
