@import "../../global";

.tabs {
  display: flex;
  flex-direction: row;

  .tab {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-right: $gut-width;
    padding-bottom: 10px;
    user-select: none;
    width: max-content;
    cursor: pointer;

    &:hover {
      .name {
        color: $accent-primary;
      }
    }

    &.hasSettings {
      &.selected {
       padding-right: 20px;
      }
    }

    &.selected {
      .name {
        color: $text-primary;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% + 16px);
        height: 3px;
        bottom: 0;
        left: -8px;
        background-color: $accent-primary;
      }
    }

    &.selectedThin {
      .name {
        color: $text-primary;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% + 2px);
        height: 3px;
        bottom: 0;
        left: -1px;
        background-color: $accent-primary;
      }
    }

    .icon {
      position: relative;
      top: 0;
      margin-right: 5px;
    }

    .name {
      font-size: 1.6rem;
      line-height: 2.0rem;
      font-weight: bold;
      color: $text-secondary;
    }

    .settings {
      position: absolute;
      top: 0;
      right: -3px;
    }
  }
}
