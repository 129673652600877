@import "../../global";

.statMapper {
  .switchToMappedButton {
    @include link;
    padding: 15px 32px 0px;
  }
  .content {
    display: grid;
    grid-template-columns: 1fr 32px 1fr;
    grid-gap: $gut-width 24px;
    padding: $gut-width;
    width: calc(100vw - 128px);

    .platformGame {
      display: contents;

      .dropdown {
        grid-column: 1 / span 1;
        grid-row: 1;
      }

      .select {
        grid-column: 3 / span 1;
        grid-row: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .platformId {
          @include text-medium;
          color: $accent-secondary;
          height: 40px;
        }
      }
    }
    
    &.loading {
      grid-column: 1 / span 3;
      grid-row: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .teams {
      display: contents;

      .team {
        grid-column: auto / span 1;
        grid-row: 2;
      }

      .switchSides {
        grid-column: auto / span 1;
        grid-row: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .switch {
          background: none;
          cursor: pointer;
        }
      }
    }

    .players {
      grid-column: 1 / span 3;
      grid-row: 3;
      @include text-medium;
      display: flex;
      flex-direction: row;
      justify-content: stretch;

      .team {
        width: 100%;
        border-top: 1px solid $rule;
        border-bottom: 1px solid $rule;
        border-collapse: collapse;
        border-spacing: 16px;


        &.blue {
          border-right: 1px solid $rule;
          padding: 0 $gut-width;
        }

        &.red {
          padding-left: $gut-width;
        }

        .header {
          @include eyebrow;
          padding: 12px 16px;
          text-align: left;
          width: 33%;
        }

        .cell {
          padding: 0 16px;
          border-top: 1px solid $rule;
          border-bottom: 1px solid $rule;

          .contents {
            height: 75px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .dropdown {
            background: none;
            border: none;
          }
        }
      }
    }
  }
}
