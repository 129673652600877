@import "../../global";

.accordion {
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    cursor: pointer;
    margin-bottom: 8px;

    &:hover {
      color: $accent-primary;
    }

    &:before {
      display: block;
      position: relative;
      content: '';
      width: 12px;
      height: 20px;
      -webkit-mask: url('../../assets/svg/arrow.svg') no-repeat center;
      margin-right: 7px;
    }

    &.open {
      &:before {
        transform: scale(0.8) rotate(90deg);
      }
    }

    &.closed {
      &:before {
        transform: scale(0.8);
      }
    }

    &.grey {
      &:before {
        background-color: $text-secondary;
      }
    }

    &.white {
      &:before {
        background-color: $text-primary;
      }
    }
  }

  .container {
    animation: fadein 0.2s;

    &.hide {
      display: none;
    }
  }
}


