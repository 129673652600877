@import "../../global";


.annoucementBar {
  position: sticky;
  z-index: 999;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 100%;
  @include text-medium;
  font-weight: 550;

  a {
    color: $text-secondary;
    text-decoration: underline;
  }

  &.info {
    background-color: #4760ff;
    color: white;
  }

  &.warning {
    background-color: #EEB868;
    color: black;
  }

  .icon {
    margin-right: 8px;
  }

  .close {
    position: absolute;
    cursor: pointer;
    left: 15px;
  
    .svgIcon {
      width: 10px;
      height: 10px;
      background-color: $bg-primary;
      -webkit-mask: url('../../assets/svg/x.svg') no-repeat center;
      mask: url('../../assets/svg/x.svg') no-repeat center;
    }
  }

  @keyframes fadeOut {
    0% { opacity: 1;}
    99% { opacity: 0.01; width: 100%; height: 100%; padding: 8px; }
    100% { opacity: 0; width: 0; height: 0px; padding: 0px; display: none;}
  } 

  &.hidden {
    animation: fadeOut 1s;
    animation-fill-mode: forwards;
  }
}