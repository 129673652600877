@import "../../global";

.orderedList {

  .listItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    .label {
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 3.2rem;
      text-align: center;
      letter-spacing: 1px;
      height: 32px;
      width: 32px;
      margin-right: 16px;
    }

    .container {
      width: 100%;
    }
  }
}
