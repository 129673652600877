@import '../../global';

.mainButton {
  background-color: $accent-primary;
  border-radius: 30px;
  border: 2px solid transparent;
  cursor: pointer;
  font-family: Colfax;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  gap: 10px;
  height: 44px;
  line-height: 16px;
  padding: 10px 24px 8px;
  text-align: left;
  text-transform: uppercase;
  transition: all 0.1s ease-in-out;
  user-select: none;
  width: 185px;

  &:focus {
    outline: none;
  }

  &:hover {
    border-radius: 30px;
    border: 2px solid $btn-hover-border;
    background-color: $btn-hover-primary;
    box-shadow: 0px 0px 0px 2px $btn-hover-boxshadow;
  }

  &:disabled {
    pointer-events: none;
    background-color: $bg-disabled;
    color: $text-disabled;
  }

  &.disabled {
    pointer-events: none;
    background-color: $text-secondary;
    color: $text-disabled;
  }
}

.caretButton {
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: $accent-primary;
  border: 2px solid transparent;
  cursor: pointer;
  padding-left: 5px;
  left: -37px;
  top: -2px;
  width: 37px;
  height: 40px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;

  &:hover {
    border: 2px solid $btn-hover-border;
    background-color: $btn-hover-primary;
    box-shadow: 0px 0px 0px 2px $btn-hover-boxshadow;
  }
}

.menuOpen {
  display: block;
  position: absolute;
  top: -235%;
  left: -230px;
  width: 260px;
  background-color: $bg-primary;
  border: 1px solid $rule;
}

.menuClosed {
  display: none;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid $rule;
  cursor: pointer;

  &:hover {
    background-color: $bg-secondary;
  }
}

.menuItemBtn {
  background-color: transparent;
  font-family: 'Colfax';
  font-size: 16px;
  color: $text-primary;
  cursor: pointer;
}
