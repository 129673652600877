@import '../../global';

.messages {
  position: fixed;
  min-width: 35%;
  top: 3%;
  left: 30%;
  color: #000;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 10;

  .message {
    padding: 9px 10px;
    font-size: 1.6rem;
    line-height: 2.8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    margin-bottom: 15px;
    background-color: #dcdfe3;
    border-radius: 2px;

    .leftAligned {
      margin-top: 1%;

      .errorIcon {
        display: inline-block;
        text-align: center;
        background-color: $chaos-primary;
        border-radius: 100px;
        margin-right: 12px;
        height: 24px;
        width: 24px;
      }

      .loadingIcon {
        display: inline-block;
        position: relative;
        top: 5px;
        text-align: center;
        background-color: transparent;
        border-radius: 100px;
        margin-right: 12px;
        height: 24px;
        width: 24px;
      }

      .successIcon {
        display: inline-block;
        text-align: center;
        background-color: $success-primary;
        border-radius: 100px;
        margin-right: 12px;
        height: 24px;
        width: 24px;
      }

      .loading {
        height: 24px;
        width: 24px;
      }

      .failed {
        background-color: #dcdfe3;
        -webkit-mask: url('../../assets/svg/failed.svg') no-repeat center;
        height: 12px;
        width: 12px;
      }

      .success {
        background-color: #000;
        -webkit-mask: url('../../assets/svg/success.svg') no-repeat center;
        height: 12px;
        width: 12px;
      }
    }

    &.error {
      border-left: 8px solid $chaos-primary;
    }

    &.loading {
      border-left: 8px solid #5abbd4;
    }

    &.success {
      border-left: 8px solid $success-primary;
    }

    &.modal.success {
      animation: fadein 0.8s;
    }

    &.default {
      animation: fadein 0.8s;
    }

    .rightAligned {
      .help {
        @include eyebrow;
        color: $text-secondary;
        text-decoration-line: underline;
        margin-left: 20px;
        margin-right: 20px;
      }

      .remove {
        background: transparent;
        position: relative;
        top: 7px;
        height: 100%;
        cursor: pointer;

        .x {
          background-color: #000;
          height: 24px;
          width: 24px;
          -webkit-mask: url('../../assets/svg/closeMessage.svg') no-repeat
            center;
        }
      }
    }
  }
}
