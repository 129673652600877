@import "../../global";

.footer {
  align-items: center;
  background: $bg-tertiary;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 67px;
  padding: 15px 0px;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  border-top: 1px solid $rule;

  > * {
    &:focus {
      outline: none;
    }
    margin-right: 32px;
  }
}
