@import '../../global';

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.8rem;
  line-height: 3.2rem;
  animation: fadein 0.8s;

  th {
    padding: 6px 17px;
  }

  td {
    padding: 15px 17px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 24px;
    vertical-align: middle;
  }

  .headerCheckbox {
    position: relative;
    text-align: left;
    text-transform: uppercase;
    user-select: none;
    color: $text-secondary;
    background: $bg-secondary;
    line-height: 1.8rem;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    width: 30px !important;
  }

  .header {
    position: relative;
    text-align: left;
    text-transform: uppercase;
    user-select: none;
    color: $text-secondary;
    background: $bg-secondary;
    line-height: 1.8rem;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;

    &:hover,
    &:active {
      color: $accent-primary;
    }

    .text {
      position: relative;
      width: fit-content;

      &:before {
        @include chevron;
        right: -15px;
        top: 2px;
        transform: scale(0.5) rotate(90deg);
        background-color: $text-secondary;
      }

      &:after {
        @include chevron;
        right: -15px;
        bottom: 3px;
        transform: scale(0.5) rotate(-90deg);
        background-color: $text-secondary;
      }

      &.ascending {
        &:after {
          background-color: $accent-primary;
        }
      }

      &.descending {
        &:before {
          background-color: $accent-primary;
        }
      }
    }

    .textWithTooltip {
      &:before {
        @include chevron;
        right: -15px;
        top: 5px;
        transform: scale(0.5) rotate(90deg);
        background-color: $text-secondary;
      }
    }
  }

  .row {
    border-top: 1px solid $rule;
    border-bottom: 1px solid $rule;
    border-spacing: 8px;
    background-color: $bg-primary;
    &.clickable {
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
      &:active {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }

    &.highlight {
      border-bottom: 1px solid $tbl-row-border;
      background: $tbl-row-highlight;
    }
  }

  .tooltipLink {
    position: relative;
    top: 2px;
    padding: 10px;
  }

  .thTooltip {
    z-index: 9999;
    background: $bg-secondary;
    padding: 15px;
    border: 1px solid $rule;
    border-radius: 4px;
    color: '#FFF';
    font-family: 'Colfax';
    font-size: 16px;
    font-style: 'normal';
    font-weight: 400;
    line-height: 140%;
    text-transform: none;

    ul {
      list-style-type: disc;
      margin-left: 12px;
    }
  }
}
