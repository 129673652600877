@import '../../global';

.container {
  @include title-small;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &.left {
    flex-direction: row;
  }

  &.right {
    flex-direction: row-reverse;
  }

  .label {
    padding: 0 8px;
    cursor: pointer;
    user-select: none;
  }

  .checkbox {
    border: 1px solid $rule;
    background-color: $bg-primary;
    border-radius: 4px;
    height: 18px;
    width: 18px;

    .check {
      background-color: $accent-primary;
      -webkit-mask: url('../../assets/svg/check.svg') no-repeat center;
      height: 100%;
      width: 100%;
    }
  }
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

