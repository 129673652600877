@import './vars';

/* MIXINS */
@mixin eyebrow {
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: $text-secondary;
}

@mixin link {
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: 1px;
  color: $accent-primary;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  background: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: $text-disabled;
  }
}

@mixin title-large {
  font-size: 3.6rem;
  line-height: 4.8rem;
  color: $text-primary;
}

@mixin title-medium {
  font-size: 2.4rem;
  line-height: 4.0rem;
  color: $text-primary;
}

@mixin title-small {
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: $text-primary;
}

@mixin text-medium {
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: $text-primary;
}

@mixin text-small {
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: $text-primary;
}

@mixin flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-left {
  display: flex;
  justify-content: left;
  align-items: center;
}

@mixin grid {
  display: grid;
  max-width: 100vw;
  grid-template-columns: repeat(12, [gut-start] $gut-width [gut-end col-start] 1fr [col-end]) [gut-start] $gut-width [gut-end];
  grid-template-rows: 1fr;
}

@mixin grid-col($num-col) {
  display: grid;
  grid-template-columns: repeat($num-col - 1,[col-start] 1fr [col-end gut-start] $gut-width [gut-end]) [col-start] 1fr [col-end];
  grid-template-rows: 1fr;
}

@mixin chevron {
  display: block;
  content: '';
  position: absolute;
  width: 12px;
  height: 20px;
  -webkit-mask: url('../../assets/svg/arrow.svg') no-repeat center;
  background-color: $text-primary;
}

@mixin input {
  @include text-medium;
  background-color: $bg-secondary;
  border-radius: 6px;
  border: 1px solid $rule;
  padding: 6px 12px;
  box-sizing: border-box;
  height: 40px;
}

@mixin copy {
  display: block;
  content: '';
  position: absolute;
  width: 17px;
  height: 21px;
  -webkit-mask: url('../../assets/svg/copy.svg') no-repeat center;
  background-color: $text-secondary;
}
