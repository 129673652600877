
@import "../../global";

.border {
  position: fixed;
  z-index: 1000;
}

.test {
  background-color: #8338ec;
}

.local {
  background-color: #588157;
}

.dev {
  background-color: #fb8500;
}

.top {
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

.right {
  top: 0;
  right: 0;
  width: 4px;
  height: 100%;
}

.bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
}


.left {
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
}

.environmentIndicator {
  color: white;
  font-size: 14px;
  position: fixed;
  z-index: 1000;
  top: 30;
  right: 0;
  padding: 7px 5px 5px 5px;
}

.close {
  position: relative;
  cursor: pointer;
  margin-left: 7px;
  margin-right: 7px;

  .svgIcon {
    width: 10px;
    height: 10px;
    background-color: white;
    -webkit-mask: url('../../assets/svg/x.svg') no-repeat center;
    mask: url('../../assets/svg/x.svg') no-repeat center;
  }
}

.hideForSession {
  @include link;

  overflow: hidden;
  color: #d22a48;
  text-align: center;
  height: auto;
  text-overflow: ellipsis;
  font-family: Colfax;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
}
