/* COLORS */
$accent-hover: #6fe2ff;
$accent-primary: #5abbd4;
$accent-secondary: #e7920e;
$accent-val: #ff4655;
$accent-wr: #9c6bff;
$bg-disabled: #687077;
$bg-primary: #15171a;
$bg-quaternary: #2a2d31;
$bg-secondary: #0e1012;
$bg-tertiary: #000;
$chaos-hover: #b51919;
$chaos-primary: #f00;
$rule: #292d31;
$success-primary: #41af74;
$text-disabled: #292d31;
$text-primary: #fff;
$text-secondary: #687077;
$victory: #c79e57;
$input-disabled-background: #272727;

$btn-hover-primary: #3f95ab;
$btn-hover-border: rgba(0, 0, 0, 0.01);
$btn-hover-background-secondary: #1e2022;
$btn-hover-boxshadow: rgba(90, 187, 212, 0.5);

$btn-hover-boxshadow-tertiary: rgba(255, 255, 255, 0.5);
$btn-hover-background-tertiary: #1e2022;
$btn-hover-background-tertiary-small: #898a8b;
$btn-hover-background-tertiary-small-background: #292d31;
$btn-hover-background-tertiary-small-border: rgba(0, 0, 0, 0.01);

$btn-link-critical: #de2f2f;

$tbl-row-highlight: rgba(255, 255, 255, 0.05);
$tbl-row-border: #292d31;
/* PATHS */
$asset-path: '/assets';

/* MEASUREMENTS */
$gut-width: 32px;
