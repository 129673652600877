@import 'global';

/* FONTS */
@font-face {
  font-family: 'Colfax';
  font-weight: normal;
  src: url('./assets/ColfaxWebRegular.woff2') format('woff2');
}

@font-face {
  font-family: 'Colfax';
  font-weight: bold;
  src: url('./assets/ColfaxWebMedium.woff2') format('woff2');
}

html {
  font-size: 10px;
  box-sizing: border-box;
}

body {
  background-color: $bg-primary;
  color: $text-primary;
  font-family: 'Colfax', Helvetica, sans-serif;
  min-height: 100vh;
  min-width: 1280px;

  .accessDenied {
    @include flex-centered;
    flex-direction: column;
    margin: 100px 0;

    .error {
      margin-bottom: $gut-width;
    }

    .title {
      @include title-large;
      margin-bottom: 16px;
    }

    .description {
      @include text-medium;
    }
  }
}

/* Stylized scroll bar */
@media (pointer: fine) {
  ::-webkit-scrollbar {
    height: 14px;
    width: 14px;
    background-color: $bg-secondary;
    margin: 0 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: $rule;
    -webkit-border-radius: 1ex;
    border: 3px solid $bg-secondary;
  }

  ::-webkit-scrollbar-corner {
    background: $bg-tertiary;
  }
}
