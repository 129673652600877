@import "../../global";

.menu {
  position: relative;
  margin-left: 16px;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: none;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    height: 28px;
    padding: 0 16px;
    border: 1px solid $bg-primary;
    border-left: 1px solid $rule;


    &.open {
      border: 1px solid $rule;
      border-bottom: 1px solid $bg-secondary;
      background: $bg-secondary;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      z-index: 2;
    }


    &:focus {
      outline: none;
    }

    .ellipsis {
      height: 18px;
      width: 24px;
      -webkit-mask: url('../../assets/svg/ellipsis.svg') no-repeat center;
      background-color: $text-secondary;
    }
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: $bg-secondary;
    top: calc(100% - 1px);
    right: 0;
    width: max-content;
    border: 1px solid $rule;
    padding: 16px;
    border-radius: 6px;
    border-top-right-radius: 0;
    z-index: 1;

    .item {
      @include eyebrow;
      color: $text-primary;
      text-align: right;
      margin-bottom: 16px;
      background: none;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $accent-primary;
      }
    }
  }
}
