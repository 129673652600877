@import "../../../global";

.manual {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .id {
    width: calc(50% -  12px);

    .label {
      @include eyebrow;
      margin-bottom: 12px;
    }
  }
}
