@import "../../global";

.icon {
  display: flex;
  align-items: center;

  .label {
    margin-left: 10px;
  }
}
