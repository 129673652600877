@import "../../global";

.loading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadein 0.25s;
  height: 50vw;
  width: 100vw;

  &.simple {
    height: 80px;
    width: 80px;
  }

  .logo {
    animation: spin 1s infinite ease-in-out;
  }
}


@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }

  5% {
    transition-timing-function: ease-in;
    transform: rotateZ(-10deg);
  }

  70% {
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
    transform: rotateZ(725deg);
  }

  75% {
    transition-timing-function: ease-out;
    transform: rotateZ(720deg);
  }

  100% {
    transform: rotateZ(720deg);
  }
}

