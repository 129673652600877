@import "../../global";

.grid {
  @include grid;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  height: 100%;
  width: 100%;

  .column {
    background-color: rgba(255,0,0,0.15);
  }
}
