@import "../../global";

.localizationTable {
  @include title-medium;
}

.accordion {
  grid-column: auto / span 12;

  .header {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr [col-end]);
    grid-column-gap: $gut-width;
    grid-row-gap: $gut-width;
    background-color: $bg-secondary;
    border-top: 1px solid $rule;
    border-bottom: 1px solid $rule;
    padding: 13px 17px 11px 17px;
    @include eyebrow;
  }

  .row {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr [col-end]);
    grid-column-gap: $gut-width;
    grid-row-gap: $gut-width;
    padding: 10px 0;
    border-bottom: 1px solid $rule;
  }

  .title {
    grid-column: col-start 1 / col-end 3;

    .input {
      margin-left: 16px;
    }
  }

  .description {
    grid-column: col-start 4 / col-end 8;
  }

  .locale {
    grid-column: col-start 9 / col-end 10;
  }

  .delete {
    grid-column: col-start 12 / col-end 12;

    .button {
      @include eyebrow;
      color: $accent-primary;
      background: none;
      border: none;
      cursor: pointer;

      &.disabled {
        color: $text-secondary;
        pointer-events: none;
      }
    }
  }

  .add {
    margin-top: 24px;
    padding: 5px 18px 3px 18px;
    height: unset;
  }
}
