@import '../../global';
@import '../../commons/Sass/mixins';

.assets {
  @include grid;

  .asset {
    &:first-child {
      grid-column: col-start 1 / col-end 2;
    }

    &:nth-child(2) {
      grid-column: col-start 3 / col-end 4;
    }

    &:nth-child(3) {
      grid-column: col-start 5 / col-end 6;
    }

    &:nth-child(4) {
      grid-column: col-start 7 / col-end 8;
    }

    &:nth-child(5) {
      grid-column: col-start 9 / col-end 10;
    }

    &:nth-child(6) {
      grid-column: col-start 11 / col-end 12;
    }
  }
}


