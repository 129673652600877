@import '../../global';

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .validationMessage {
    animation: fadein 0.4s;
    @include text-medium;
    color: $chaos-primary;
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 14px;
    line-height: 15px;
    padding-top: 6px;
  }

  .isInvalid {
    border: 1px solid $chaos-primary;
    background-color: #1F1111;

    &:focus {
      border: 1px solid $chaos-primary;
    }
  }
}
