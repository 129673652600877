@import "../../global";

.assetUpload {
  @include flex-centered;
  flex-direction: column;

  .selectImage {
    @include flex-centered;
    position: relative;
    background-color: $bg-secondary;
    border: 1px solid $rule;
    box-sizing: border-box;
    height: 200px;
    width: 200px;
    padding-top: 100%;
    width: 100%;

    .select {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .removeImage {
    @include flex-centered;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    width: 200px;
    border: 1px solid $rule;
    box-sizing: border-box;
    padding-top: 100%;
    width: 100%;

    &:hover {
      .remove {
        opacity: 1;
      }
    }

    .remove {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $chaos-primary;
      opacity: 0;
    }
  }

  .selectGeneric {
    border: 1px dashed $rule;
    padding: 10px 40px;

    .select {
      height: 24px;
      padding: 2px 12px;
      margin: 8px 0;
    }
  }

  .removeGeneric {
    display: flex;
    flex-direction: column;
    border: 1px dashed $rule;
    padding: 10px 40px;
    text-align: center;

    .remove {
      height: 24px;
      padding: 2px 12px;
      margin: 8px 0;
      background-color: $chaos-primary;
    }
  }

  .titleText {
    @include title-small;
    margin: 15px auto 0 0;
  }
}

