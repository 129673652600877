@import "../../global";

.section {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr [col-end]);
  grid-gap: 16px $gut-width;
  margin: 0 $gut-width;
  padding: $gut-width 0;
  border-bottom: 1px solid $rule;

  &:last-of-type {
    border-bottom: none;
  }

  .title {
    @include title-medium;
    grid-column: col-start 1 / col-end 12;
  }
}
