@import "../../../global";

.list {
  margin-bottom: 70px;

  .filter {
    @include grid;
    margin: $gut-width 0;

    .input {
      grid-column: col-start 1 / col-end 4;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    margin-left: $gut-width;

    .details {
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: $text-secondary;
      animation: pulsate 2s ease-out;
      animation-iteration-count: infinite; 
      opacity: 1;
    }
    @keyframes pulsate {
      0% { 
          opacity: 0.5;
      }
      50% { 
          opacity: 1.0;
      }
      100% { 
          opacity: 0.5;
      }
  }
  }

  .table {
    @include grid;

    .data {
      grid-column: col-start 1 / col-end 12;

      .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .action {
          @include link;
        }
      }
    }
  }
}
