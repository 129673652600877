@import "../../global";

.localizationsList {
  grid-column: col-start 1 / col-end 12;
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: $gut-width;

  .localization {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
