@import "../../global";

.modal {
  @include flex-centered;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  z-index: 201;
  animation: fadein 0.25s;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 600px;
    background-color: $bg-secondary;

    .header {
      @include flex-left;
      position: relative;
      width: 100%;
      height: 56px;
      border-top: 1px solid $rule;
      border-right: 1px solid $rule;
      border-left: 1px solid $rule;
      background-color: $bg-tertiary;

      .title {
        @include title-medium;
        padding-left: 15px;
      }

      .close {
        position: absolute;
        right: 25px;
        transform: scale(2.0);
        width: 10px;
        height: 10px;
        background-color: white;
        -webkit-mask: url('../../assets/svg/x.svg') no-repeat center;
        cursor: pointer;
      }
    }

    .content {
      position: relative;
      width: 100%;
      height: auto;
      border: 1px solid $rule;
    }

    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 68px;
      border-right: 1px solid $rule;
      border-bottom: 1px solid $rule;
      border-left: 1px solid $rule;
      padding: 5px 0;

      .left {
        .delete {
          cursor: pointer;
          margin-left: $gut-width;

          .text {
            font-size: 1.4rem;
            line-height: 1.8rem;
            letter-spacing: 1px;
            color: $chaos-primary;
            text-transform: uppercase;
            font-weight: bold;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .cancel {
          cursor: pointer;
          margin-right: $gut-width;

          .text {
            font-size: 1.4rem;
            line-height: 1.8rem;
            letter-spacing: 1px;
            color: $accent-primary;
            text-transform: uppercase;
            font-weight: bold;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .confirm {
          @include flex-centered;
          cursor: pointer;
          margin-right: $gut-width;

          .text {
            text-transform: uppercase;
            font-weight: bold;
          }
        }
      }
    }
  }
}
