@import './commons/Sass/vars';
@import './commons/Sass/mixins';

/* ANIMATIONS */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.link {
  @include link;
}

.narrowButton {
  padding: 5px 18px;
  height: 24px;
}

.notAllowed {
  cursor: not-allowed;
}
