@import "../../global";

.sidebar {
  position: fixed;
  z-index: 30000001;
  top: 0;
  left: 0;
  width: 260px;
  height: 100%;
  padding: 30px;
  background-color: $bg-secondary;
  border-right: 6px solid $rule;
  font-size: 16px;
  box-shadow: 0 0 16px black;

  h1 {
    @include title-medium;
    color: #5abbd4;
  }

  .version {
    @include text-small;
    font-weight: bold;
    margin-top: 6px;
    margin-left: 20px;
    margin-right: 6px;
    color: white;
  }

  .environmentSelectorSection {
    margin-top: 15px;
  }

  .environmentSelectorLabel {
    font-weight: bold;
  }

  .environmentSelector {
    width: 100%;
    font-size: 14px;
    background-color: $bg-primary;
    margin: 10px auto;
    padding: 2px 4px;
    border: 1px solid $rule;
    color: #999;
    font-family: consolas, monaco, monospace;
  }
}

