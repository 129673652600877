@import '../../global';

.reset {
  display: flex;
  align-items: center;

  .btn {
    @include link;
    font-family: Colfax;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    color: $chaos-primary;
    margin-right: $gut-width;
  }

  .msg {
    @include text-medium;
    color: $text-secondary;
  }
}
