@import "../../global";

.dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 260px;
  user-select: none;

  .optionContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &.sportSelector {
    min-width: auto;
    height: 100%;

    &.open {
      .currentOption {
        border: none;

        .chevron {
          transform: rotate(270deg) scale(0.9);
        }

        &:hover {
          border: none;
        }
      }
    }

    .currentOption {
      height: 100%;
      border: none;
      background-color: $bg-tertiary;

      .chevron {
        transform: rotate(90deg) scale(0.9);
        background-color: $text-secondary;
      }

      .icon {
        width: 43px;
        height: 36px;
      }

      &:hover {
        border: none;
      }
    }

    .options {
      .option {
        width: 100%;
        padding: 12px 0;
        background-color: $bg-tertiary;

        .icon {
          width: 43px;
          height: 36px;
        }
      }
    }
  }

  &.iconOnly {
    min-width: auto;

    .currentOption {
      .icon {
        margin-right: 8px;

        > img {
          height: auto;
        }
      }
    }

    .options {
      width: calc(100% - 20px);

      .option {
        .icon {
          > img {
            height: auto;
          }
        }
      }
    }
  }

  &.disabled {
    .currentOption {
      box-sizing: border-box;
      color: $text-secondary;

      &:after {
        @include chevron;
        right: 12px;
        top: 0;
        transform: translateY(50%) rotate(90deg);
        background-color: $text-secondary;
      }

      .text {
        color: $text-secondary;
      }
    }
  }

  &:focus {
    outline: none;
  }

  &.open {
    .currentOption {
      border: 1px solid $accent-primary;

      &:hover {
        border: 1px solid $accent-primary;
      }

      .chevron {
        transform: rotate(270deg);
      }
    }
  }

  .title {
    @include eyebrow;
    margin-bottom: 12px;
  }

  .currentOption {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid $rule;
    border-radius: 6px;
    height: 40px;
    background: $bg-secondary;
    cursor: pointer;

    &:hover {
      border: 1px solid $text-secondary;
    }

    .chevron {
      height: 20px;
      width: 12px;
      transform: rotate(90deg);
      -webkit-mask: url('../../assets/svg/arrow.svg') no-repeat center;
      background-color: $text-primary;
      margin-right: 12px;
    }

    .text {
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: $text-primary;
      margin-left: 12px;
      width: 100%;
    }

    .icon {
      margin-left: 12px;
      display: flex;
      justify-content: center;
      width: 20px;
      height: 20px;
    }
  }

  .options {
    position: absolute;
    border: 1px solid $rule;
    border-radius: 6px;
    max-height: 300px;
    width: 100%;
    overflow-y: auto;
    z-index: 10;

    &.down {
      top: 100%;
    }

    &.up {
      bottom: 100%;
    }

    .option {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 40px;
      background-color: $bg-secondary;
      cursor: pointer;

      &:hover:not(.selected) {
        background-color: $bg-primary;

        .text {
          color: $accent-primary;
        }
      }

      &.selected {
        .text {
          color: $accent-primary;
        }
      }

      .text {
        font-size: 1.6rem;
        line-height: 2.8rem;
        color: $text-primary;
        margin-left: 12px;
      }

      .icon {
        position: relative;
        margin-left: 12px;
        width: 24px;
        height: 24px;

        > * {
          width: 100%;
        }
      }
    }
  }
}
