@import "../../global";

.button {
  height: 36px;
  border-radius: 100px;
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding: 9px 24px;
  background-color: $accent-primary;
  border: none;
  cursor: pointer;
  user-select: none;
  transition: all .1s ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $accent-hover;
  }

  &:disabled {
    pointer-events: none;
    background-color: $bg-disabled;
    color: $text-disabled;
  }

  &.disabled {
    pointer-events: none;
    background-color: $text-secondary;
    color: $text-disabled;
  }
}

.input {
  @include input;
  width: 100%;

  &:hover {
    border: 1px solid $text-secondary;
  }

  &:focus {
    outline: none;
    border: 1px solid $accent-primary;
  }

  &:disabled {
    pointer-events: none;
    color: $text-disabled;
  }
}

.textarea {
  width: 100%;
  background: $bg-secondary;
  border: 1px solid $rule;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 6px 13px;
  font-size: 1.6rem;
  line-height: 2.8rem;
  min-height: 68px;
  color: $text-primary;
  font-family: 'Colfax', Helvetica, sans-serif;

  &:hover {
    border: 1px solid $text-secondary;
  }

  &:focus {
    outline: none;
    border: 1px solid $accent-primary;
  }
}

