@import "../../global";

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.8rem;
  line-height: 3.2rem;
  animation: fadein 0.8s;

  th {
    padding: 6px 17px;
  }

  td {
    padding: 15px 17px;
    vertical-align: middle;
  }

  .header {
    position: relative;
    text-align: left;
    text-transform: uppercase;
    user-select: none;
    color: $text-secondary;
    background: $bg-secondary;
    line-height: 1.8rem;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 14px;

    .text {
      position: relative;
      width: fit-content;
    }
  }

  .row {
    border-top: 1px solid $rule;
    border-bottom: 1px solid $rule;
    border-spacing: 8px;

    &.clickable {
      cursor: pointer;

      &:hover {
        background-color: $bg-secondary;
      }
    }
  }
}
