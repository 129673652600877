@import "../../global";

.multiselect {
  position: relative;
  @include text-medium;
  width: 100%;

  .itemContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &.disabled {
    color: $text-secondary;
  }

  .selectedItem {
    background-color: $bg-tertiary;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $gut-width;
    user-select: none;

    .chevron {
      height: 16px;
      width: 16px;
      -webkit-mask: url('../../assets/svg/arrow.svg') no-repeat center;

      &.open {
        transform: rotate(270deg);
        background-color: $accent-primary;
      }

      &.closed {
        transform: rotate(90deg);
        background-color: $text-secondary;
      }
    }
  }

  .menu {
    position: absolute;
    bottom: 100%;
    left: 0;
    background-color: $bg-secondary;
    border: 1px solid $rule;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 32px 0px 32px;
    box-sizing: border-box;
    max-height: 50vh;
    overflow: auto;

    .menuBlock {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      cursor: pointer;
      user-select: none;

      &.selected {
        color: $text-primary;
      }

      &.unselected {
        color: $text-secondary;
      }

      &:hover {
        color: $accent-primary;
      }

      .check {
        background-color: $accent-primary;
        -webkit-mask: url('../..//assets/svg/check.svg') no-repeat center;
        height: 16px;
        width: 16px;
      }
    }

    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      margin-left: 20px;
      cursor: pointer;
      user-select: none;

      &.selected {
        color: $text-primary;
      }

      &.unselected {
        color: $text-secondary;
      }

      &:hover {
        color: $accent-primary;
      }

      .check {
        background-color: $accent-primary;
        -webkit-mask: url('../../assets/svg/check.svg') no-repeat center;
        height: 16px;
        width: 16px;
      }
    }
  }
}
