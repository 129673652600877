@import '../../global';

.radio {
  background-color: transparent;

  display: flex;
  align-items: center;
  align-self: stretch;
  cursor: pointer;

  svg {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
  }

  .selected {
    width: 24px;
    height: 24px;
    background-color: $accent-primary;
    color: $accent-primary;
    -webkit-mask: url('../../assets/svg/radioSelected.svg') no-repeat center;
  }

  .unselected {
    width: 24px;
    height: 24px;
    background-color: $text-secondary;
    color: $text-secondary;
    -webkit-mask: url('../../assets/svg/radioUnselected.svg') no-repeat center;
  }

  .label {
    margin-left: 10px;
    display: inline-flex;
    color: $text-primary;
    font-family: Colfax;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover {
      cursor: pointer;
    }
  }
}
