@import "../../global";

.scrollable {
  overflow: auto;
  cursor: move;

  /* We only support Chrome on EMP, so this is the only browser we need to style */
  /* the scrollbar for.  Just setting its height to 0 is enough to 'hide' it. */
  &::-webkit-scrollbar {
    height: 0;
  }
}
