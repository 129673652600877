@import "../../global";

.colorPicker {
  position: relative;
  display: flex;
  flex-direction: column;

  .input {
    padding-left: 45px;
    text-transform: uppercase;
  }

  .color {
    position: absolute;
    height: 20px;
    width: 20px;
    box-sizing: border-box;
    border: 1px solid $rule;
    border-radius: 2px;
    bottom: 10px;
    left: 10px;
  }
}
