@import '../../global';

.container {
  background-color: transparent;
  width: 99px;
  height: 40px;

  .search {
    background-color: transparent;

    .active {
      background: $btn-hover-background-tertiary;
      border-radius: 4px;
      border: 2px solid $btn-hover-border;
      box-shadow: 0px 0px 0px 2px $btn-hover-boxshadow-tertiary;
      color: $text-primary;

      picture {
        width: 24px;
        height: 24px;
        background-color: $text-primary;
        -webkit-mask: url('../../assets/svg/filter.svg') no-repeat center;
      }
    }

    .searchButton {
      align-items: center;
      background-color: $text-disabled;
      border-radius: 4px;
      border: 2px solid transparent;
      color: $text-primary;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      font-style: normal;
      font-weight: 500;
      gap: 8px;
      justify-content: center;
      padding: 6px 12px 6px 8px;
      text-align: center;

      picture {
        width: 24px;
        height: 24px;
        background-color: $text-primary;
        -webkit-mask: url('../../assets/svg/filter.svg') no-repeat center;
      }

      .text {
        color: $text-primary;
        display: inline-flex;
        font-family: Colfax;
        font-size: 14px;
        line-height: 28px;
        text-transform: uppercase;
      }

      &:hover,
      &:focus {
        background: $btn-hover-background-tertiary;
        border-radius: 4px;
        border: 2px solid $btn-hover-border;
        box-shadow: 0px 0px 0px 2px $btn-hover-boxshadow-tertiary;
        color: $text-primary;

        picture {
          width: 24px;
          height: 24px;
          background-color: $text-primary;
          -webkit-mask: url('../../assets/svg/filter.svg') no-repeat center;
        }
      }

      &:disabled {
        pointer-events: none;
        background-color: $bg-disabled;
        color: $text-disabled;
      }

      &.disabled {
        pointer-events: none;
        background-color: $text-secondary;
        color: $text-disabled;
      }
    }
  }

  .menuOpen {
    background-color: $bg-primary;
    border-radius: 8px;
    border: 1px solid $text-disabled;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    display: block;
    margin-left: -2px;
    margin-top: -114px;
    min-width: 275px;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .menuClosed {
    display: none;
  }
}
