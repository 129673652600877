@import '../../global';

.invitation {
  height: 80vh;
  width: 100%;

  .approvalFailed {
    @include flex-centered;
    flex-direction: column;
    margin: 100px 0;

    .error {
      margin-bottom: $gut-width;
    }

    .title {
      @include title-large;
      margin-bottom: 16px;
    }

    .description {
      @include text-medium;
    }
  }

  .approved {
    @include flex-centered;
    flex-direction: column;
    margin: 100px 0;
    .title {
      @include title-large;
      margin-bottom: 16px;
    }

    .description {
      @include text-medium;
    }
  }
}
