@import '../../global';

.confirm {
  @include flex-centered;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  z-index: 500;
  animation: fadein 0.25s;

  .container {
    @include flex-centered;
    align-items: center;
    background-color: $bg-secondary;
    border: 1px solid $rule;
    display: flex;
    flex-direction: column;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    max-width: 20%;
    padding: 16px 16px;
    position: relative;

    border-radius: var(--corner-radius-80, 8px);
    border: 1px solid var(--colors-neutral-grey-14, #242424);
    background: #1e1e1e;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      transform: scale(1.6);
      width: 10px;
      height: 10px;
      background-color: $text-primary;
      -webkit-mask: url('../../assets/svg/x.svg') no-repeat center;
      cursor: pointer;
    }

    .header {
      padding-bottom: 40px;
    }

    .content {
      @include flex-centered;
      flex-direction: column;
      padding-top: 5px;
      padding-bottom: 5px;

      .body,
      .title {
        color: $text-primary;
        text-align: center;
        font-family: Colfax;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
      }

      .warn {
        color: #d22a48;
      }

      .subbody {
        padding: 5px 0;
        color: #8a8a8a;
        text-align: center;
        font-family: Colfax;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }
    }

    .buttons {
      @include flex-centered;
      flex-direction: row;

      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin: 0;
      width: 100%;
      padding-top: 10px;

      .cancel {
        @include link;

        align-items: center;
        color: #8a8a8a;
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
        font-family: Colfax;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        gap: 10px;
        justify-content: center;
        line-height: 20px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        height: auto;
      }

      .actions {
        @include flex-centered;

        .onConfirm {
          @include link;

          overflow: hidden;
          color: #d22a48;
          text-align: center;
          height: auto;
          text-overflow: ellipsis;
          font-family: Colfax;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 125% */
        }

        .action {
          border-right: 1px solid $rule;

          &:last-of-type {
            border: none;
          }
        }
      }
    }
  }
}
