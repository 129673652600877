@import "../../global";

.panel {
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: $bg-secondary;
  border: 1px solid $rule;

  .title {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    width: 100%;
    padding: 5px 0;
    border-radius: 5px 5px 0 0;
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 5px;

    .important {
      font-size: 2.8rem;
      font-style: bold;
      color: $text-secondary;
      margin-bottom: 5px;

      &:hover {
        color: $accent-hover;
      }
    }

    .tips {
      font-size: 1.4rem;
      font-style: italic;
      color: $text-secondary;
      margin-bottom: 5px;
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}

