@import '../../global';

.container {
  @include title-small;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &.left {
    flex-direction: row;
  }

  &.right {
    flex-direction: row-reverse;
  }

  .label {
    padding: 0 8px;
    cursor: pointer;
    user-select: none;
  }

  .checkboxInverted {
    border: 1px solid $rule;
    background-color: $accent-primary;
    border-radius: 4px;
    height: 18px;
    width: 18px;

    .checked {
      background-color: $bg-primary;
      border-bottom: 1px solid transparent;
      color: $bg-primary;
      -webkit-mask: url('../../assets/svg/check.svg') no-repeat center;
      height: 18px;
      width: 18px;
    }

    .partialChecked {
      background-color: $bg-primary;
      color: $bg-primary;
      border-bottom: 1px solid $rule;
      -webkit-mask: url('../../assets/svg/partialCheck.svg') no-repeat center;
      height: 18px;
      width: 18px;
    }
  }

  .unChecked {
    border: 1px solid $rule;
    background-color: $bg-primary;
    border-radius: 4px;
    height: 18px;
    width: 18px;
  }

  .checkbox {
    border: 1px solid $rule;
    background-color: $bg-primary;
    border-radius: 4px;
    height: 18px;
    width: 18px;
  }
}
