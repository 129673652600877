@import "../../global";

.nav {
  display: contents;

  .navBar {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: $bg-tertiary;
    border-bottom: 1px solid $rule;

    &.lol {
      .item {
        &:hover {
          color: $accent-primary;
        }

        &.selected {
          &::after {
            background-color: $accent-primary;
          }
        }
      }
    }

    &.val {
      .item {
        &:hover {
          color: $accent-val;
        }

        &.selected {
          &::after {
            background-color: $accent-val;
          }
        }
      }
    }

    &.wr {
      .item {
        &:hover {
          color: $accent-wr;
        }

        &.selected {
          &::after {
            background-color: $accent-wr;
          }
        }
      }
    }

    .logo {
      width: 80px;
      height: 22px;
      margin: 0 16px 0 20px;
    }

    .sportDropdown {
      position: relative;
      margin-left: 16px;
      padding-right: 8px;

      &::after {
        position: absolute;
        content: '';
        display: block;
        right: 0;
        top: 15%;
        height: 70%;
        width: 1px;
        background-color: $rule;
      }
    }

    .item {
      @include eyebrow;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin: 0 16px;
      user-select: none;
      position: relative;

      &.selected {
        color: $text-primary;

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
        }
      }
    }

    .version {
      @include text-small;
      margin-top: 6px;
      margin-left: auto;
      margin-right: 6px;
      color: $text-secondary;
    }

    .slack {
      position: fixed;
      right: 20px;
      top: 86px;
      color: $text-primary;
      font-size: 1.6rem;
      font-weight: bold;
      margin-left: auto;
      padding: 7px 15px;
      border-radius: 20px;
      background-color: $success-primary;
      z-index: 1;
    }
  }
}

