@import '../../global';

.checkBoxGroup {
  width: 100%;

  .checkbox {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid $rule;
    padding: 12px 16px !important;
    background-color: $bg-secondary;
    text-transform: capitalize;

    &:hover {
      background-color: $bg-primary;
    }
  }
}
