@import "../../global";

.label {
  display: flex;
  flex-direction: column;

  .title {
    @include eyebrow;
    margin-bottom: 12px;
  }
}
